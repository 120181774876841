<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="cl-button">
        <b-row>
          <b-col lg="3" md="6">
            <iq-card>
              <template v-slot:body>
                <div class="text-left"><span>SKU</span></div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="value-box">
                    <h6 class="mb-0">{{ details.sku }}</h6>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="3" md="6">
            <iq-card>
              <template v-slot:body>
                <div class="text-left"><span>NOMBRE</span></div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="value-box">
                    <h6 class="mb-0">{{ details.product }}</h6>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="3" md="6">
            <iq-card>
              <template v-slot:body>
                <div class="text-left"><span>MARCA</span></div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="value-box">
                    <h6 class="mb-0">{{ details.marca }}</h6>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="3" md="6">
            <iq-card>
              <template v-slot:body>
                <div class="text-left"><span>COLOR</span></div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="value-box">
                    <h6 class="mb-0">{{ details.color }}</h6>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12" class="cl-button">
        <iq-card class="pt-2">
          <template v-slot:headerTitle>
            <h4 class="card-title">Áreas y Ubicaciones</h4>
          </template>
          <template v-slot:headerAction>
            <b-button
              variant="primary"
              @click="$refs.change.show(getRecordTable, $route.params.id)"
            >
              Movimiento de stock
            </b-button>
          </template>
          <template v-slot:body>
            <div class="text-center text-danger my-2" v-if="state">
              <b-spinner type="grow" class="align-middle"></b-spinner>
              <strong> Cargando datos...</strong>
            </div>
            <table class="table m-2 col-lg-12 col-md-12 col-sm-12" v-else>
              <thead>
                <tr>
                  <th></th>
                  <th
                    v-for="(red, idx) in getRecordTable.areas"
                    :key="`${red} - ${idx}`"
                  >
                    {{ red.name }}
                  </th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(loc, index) in getRecordTable.locations"
                  :key="`${loc.name} - ${index}`"
                >
                  <th>{{ loc.name }}</th>
                  <td
                    v-for="(area, idx_area) in loc.areas"
                    :key="`${area.slug} - ${idx_area}`"
                  >
                    {{ area.quantity }}
                  </td>
                  <th>{{ sumTable(loc.slug, "h") }}</th>
                </tr>
                <tr>
                  <th>TOTAL</th>
                  <th
                    v-for="(red, idx) in getRecordTable.areas"
                    :key="`${red} - ${idx}`"
                  >
                    {{ sumTable(red.slug, "v") }}
                  </th>
                  <th>{{ details.stockTotal }} Stock total</th>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>
      </b-col>
      <b-col sm="12">
        <b-row>
          <b-col md="6" class="profile-left">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-center text-success">
                  <i class="ri-arrow-up-circle-line h1"></i> Ingresos De
                  Productos
                </h4>
              </template>
              <template v-slot:body>
                <table-details
                  class="custom-card"
                  :rows="getRecord.ingreso"
                  :colum="ingreso"
                  :state="state"
                />
              </template>
            </iq-card>
          </b-col>
          <b-col md="6" class="profile-center">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-center text-danger">
                  <i class="ri-arrow-down-circle-line h1"></i> Egresos De
                  Producto
                </h4>
              </template>
              <template v-slot:body>
                <table-details
                  class="custom-card"
                  :rows="getRecord.egreso"
                  :colum="egreso"
                  :state="state"
                />
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <change ref="change" />
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import tableDetails from "@/components/core/table/TableStockDetails.vue";
import change from "@/components/core/modals/ChangeStock.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DetailsStock",
  components: {
    tableDetails,
    change,
  },
  async mounted() {
    await this.listArea();
    await this.listUbication();
    await this.productRecord(this.id);
    this.$store.commit("SET_BREADCRUMB", this.items);
    core.index();
  },
  data() {
    return {
      id: this.$route.params.id,
      state: false,
      ingreso: [
        { label: "Fecha", key: "date", class: "text-center" },
        { label: "Área", key: "area", class: "text-center" },
        { label: "Ubicación", key: "ubicacion", class: "text-center" },
        { label: "Cantidad", key: "quantity", class: "text-center" },
        { label: "", key: "option", class: "text-center" },
      ],
      ingresos: [],
      egreso: [
        { label: "Fecha", key: "date", class: "text-center" },
        { label: "Área", key: "area", class: "text-center" },
        { label: "Ubicación", key: "ubicacion", class: "text-center" },
        { label: "Cantidad", key: "quantity", class: "text-center" },
        { label: "", key: "option", class: "text-center" },
      ],
      egresos: [],
      items: [
        {
          html: "Inicio",
          to: "/",
        },
        {
          text: "Listado General De Stock",
          to: "/products/stock",
        },
        {
          text: "Detalles del Stock",
          active: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      listArea: "area/listArea",
      listUbication: "ubication/listUbication",
      productRecord: "products/productRecord",
    }),
    sumTable(slug, type) {
      let sum = 0;
      if (type === "h") {
        const location = this.getRecordTable.locations.filter(
          (loc) => loc.slug === slug
        )[0];
        location.areas.forEach((area) => {
          sum += area.quantity;
        });
      } else {
        this.getRecordTable.locations.forEach((loc) => {
          const area = loc.areas.filter((area) => area.slug === slug)[0];
          sum += area.quantity;
        });
      }
      return sum;
    },
  },
  computed: {
    ...mapGetters({
      details: "products/getDetaills",
      getRecord: "products/getRecord",
      getRecordTable: "products/getRecordTable",
    }),
  },
};
</script>
<style scoped>
.custom-card {
  margin-top: -70px !important;
}
@media (max-width: 991px) {
  .custom-card {
    margin-top: -20px !important;
  }
}
.cl-top {
  margin-top: -3rem;
}
.cl-button {
  margin-bottom: -1rem;
}
</style>
