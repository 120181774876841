<template>
  <div>
    <b-modal
      id="modals"
      size="lg"
      hide-footer
      no-close-on-backdrop
      :title="title"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)" class="m-3">
          <h4 class="mb-2">Origen</h4>
          <b-row>
            <b-col md="5">
              <!-- SELECT UBICACIONES -->
              <div class="form-group">
                <b-form-group>
                  <ValidationProvider
                    name="Ubicación de origen"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      label="name"
                      v-model="location"
                      placeholder="Ubicación"
                      :options="locationes"
                      @input="setLocation()"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                      <template #no-options="{}"> Sin Resultados... </template>
                    </v-select>
                    <small class="text-danger" v-if="validation"
                      >Origen y destino no pueden ser iguales</small
                    >
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </div>
            </b-col>
            <b-col md="5">
              <!-- SELECT AREA -->
              <div class="form-group">
                <b-form-group>
                  <ValidationProvider
                    name="Área de origen"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      label="name"
                      :disabled="style"
                      :options="areas.filter((item) => item.quantity !== 0)"
                      v-model="area"
                      @input="setArea()"
                      placeholder="Área"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                      <template #no-options="{}"> Sin Resultados... </template>
                    </v-select>
                    <small class="text-danger" v-if="validation"
                      >Origen y destino no pueden ser iguales</small
                    >
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </div>
            </b-col>
            <b-col md="2" class="ml-auto">
              <!-- cantidad -->

              <b-form-input
                :disabled="true"
                v-model="quantityOrigin"
                type="text"
                placeholder="Cantidad"
              ></b-form-input>
            </b-col>
          </b-row>
          <hr />
          <h4 class="mb-2">Destino</h4>
          <b-row>
            <b-col md="5">
              <!-- SELECT UBICACIONES -->
              <div class="form-group">
                <b-form-group>
                  <ValidationProvider
                    name="Área de destino"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      label="name"
                      :disabled="quantityOrigin == 0 ? true : false"
                      v-model="form.location"
                      :options="getUbication"
                      placeholder="Ubicación"
                      @input="setLocationD()"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                      :selectable="
                        (item) => (item.id === 1 ? item.disabled : item)
                      "
                    >
                      <template #no-options="{}">
                        Sin Resultados,<b-button
                          variant="link"
                          block
                          @click="$refs.create.showModal()"
                          >Agregar ubicación</b-button
                        >
                      </template>
                      <template v-slot:option="option">
                        <slot>
                          {{ option.name }}
                          <button
                            v-if="option.id === 1 ? true : false"
                            class="btn btn-link btn-block"
                            @click="$refs.create.showModal()"
                          >
                            Agregar ubicación
                          </button></slot
                        >
                      </template>
                    </v-select>
                    <small class="text-danger" v-if="validation"
                      >Origen y destino no pueden ser iguales</small
                    >
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </div>
            </b-col>
            <b-col md="5">
              <!-- SELECT AREA -->
              <div class="form-group">
                <b-form-group>
                  <ValidationProvider
                    name="Área de destino"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      label="name"
                      :disabled="aread === true ? true : false"
                      placeholder="Área"
                      v-model="form.area"
                      :options="getAreaList"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                      @input="validateMovemet()"
                       :selectable="
                        (item) => (item.id === 1 ? item.disabled : item)
                      "
                    >
                      <template #no-options="{}">
                        Sin Resultados,<b-button
                          variant="link"
                          block
                          @click="$refs.createOrUpdate.showModal()"
                          >Agregar área</b-button
                        >
                      </template>
                      <template v-slot:option="option">
                        <slot>
                          {{ option.name }}
                          <button
                            v-if="option.id === 1 ? true : false"
                            class="btn btn-link btn-block"
                            @click="$refs.createOrUpdate.showModal()"
                          >
                            Agregar área
                          </button></slot
                        >
                      </template>
                    </v-select>
                    <small class="text-danger" v-if="validation"
                      >Origen y destino no pueden ser iguales</small
                    >
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </div>
            </b-col>
            <b-col md="2">
              <!-- cantidad -->
              <ValidationProvider
                name="Cantidad"
                rules="required|numeric"
                v-slot="{ errors }"
              >
                <b-form-input
                  :disabled="quantityOrigin == 0 ? true : false"
                  v-model="form.quantity"
                  type="number"
                  placeholder="Cantidad"
                  @input="setQuantity()"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                ></b-form-input>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <small class="text-danger" v-if="warning"
                >No puede seleccionar una cantidad mayor a la existente</small
              >
            </b-col>
            <b-col md="12">
              <!-- comentario -->
              <div class="form-group">
                <b-form-group label="Comentario">
                  <ValidationProvider
                    name="Comentario"
                    rules="required|min:10"
                    v-slot="{ errors }"
                  >
                    <b-form-textarea
                      :disabled="quantityOrigin == 0 ? true : false"
                      placeholder="Ingresa una descripción referente al movimiento de stock"
                      v-model="form.description"
                      rows="1"
                      max-rows="1"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    ></b-form-textarea>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
      <hr />
      <b-button
        variant="primary"
        class="mr-2"
        :class="
          location !== '' &&
          form.area !== '' &&
          form.description !== '' &&
          form.quantity !== ''
            ? ''
            : 'disabled'
        "
        @click="onSubmit()"
        >Guardar
      </b-button>
      <b-button
        variant=" iq-bg-danger mr-1"
        class="mr-2"
        @click="$bvModal.hide('modals')"
        >Cerrar</b-button
      >
    </b-modal>
    <modals-ubication ref="create" />
    <modals ref="createOrUpdate" />
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import modals from "@/components/core/modals/AreaModals.vue";
import modalsUbication from '@/components/core/modals/LocationModals'
export default {
  name: "Modals",
  async mounted() {
    this.diccionary.location = this.getUbication;
  },
   components: {
     modals,
     modalsUbication
  },
  data() {
    return {
      id: "",
      areas: [],
      locationes: [],
      title: "Movimiento De Stock",
      location: "",
      area: "",
      quantityOrigin: "",
      style: true,
      locationd: true,
      aread: true,
      quantityd: true,
      warning: false,
      diccionary: {
        area: [],
        location: [],
      },
      areasD: [],
      locationesD: [],
      form: {
        location: "",
        area: "",
        quantity: "",
        description: "",
      },
      validation: false,
    };
  },
  methods: {
    show(data, id) {
      console.log(data);
      this.id = id;
      this.locationes = data.locations;
      this.areas = data.areas;
      (this.form = {
        location: "",
        area: "",
        quantity: "",
        description: "",
      }),
        (this.location = ""),
        (this.area = ""),
        this.$bvModal.show("modals");
    },
    async setLocation() {
      this.validateMovemet();
      if (this.location !== 0 && this.location !== null) {
        this.style = false;
        this.areas = this.location.areas.filter((item) => item.quantity !== 0);
      } else {
        this.style = true;
        this.area = "";
        this.quantityOrigin = "";
      }
    },
    async setArea() {
      this.validateMovemet();
      if (this.area !== 0 && this.area !== null) {
        this.quantityOrigin = this.area.quantity;
      } else {
        this.quantityOrigin = "";
      }
    },
    setLocationD() {
      this.validateMovemet();
      if (this.form.location !== 0 && this.form.location !== null) {
        this.aread = false;
      } else {
        this.aread = true;
        this.quantityd = true;
        this.form.area = "";
        this.form.quantity = null;
      }
    },
    validateMovemet() {
      if (
        this.location.name === this.form.location.name &&
        this.area.name === this.form.area.name
      ) {
        this.validation = true;
      } else {
        this.validation = false;
      }
    },
    setQuantity() {
      if (this.form.quantity > this.quantityOrigin) {
        this.form.quantity = "";
        this.warning = true;
      } else {
        this.warning = false;
      }
    },
    async onSubmit() {
      const payload = {
        id: this.id,
        origin: {
          location: this.location.id,
          area: this.area.id,
        },
        destination: {
          location: this.form.location.id,
          area: this.form.area.id,
        },
        quantity: this.form.quantity,
        description: this.form.description,
      };
      try {
        await this.$store.dispatch("products/movementStock", payload);
        core.showSnackbar("success", "Movimiento Realizado");
        this.$bvModal.hide("modals");
      } catch (error) {
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      getAreaList: "area/getAreaList",
      getUbication: "ubication/getUbication",
    }),
  },
};
</script>
