<template>
  <b-row>
    <b-col lg="4" class="mb-3 ml-auto">
      <b-form-group class="mb-0">
        <b-input-group>
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Buscar..."
          ></b-form-input>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col md="12" class="table-responsive">
      <b-table
        hover
        :items="rows"
        :fields="colum"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        @filtered="onFiltered"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <div v-if="state">
              <b-spinner type="grow" class="align-middle"></b-spinner>
              <strong> Cargando datos...</strong>
            </div>
            <span class="h5" v-else>Sin registros</span>
          </div>
        </template>
        <template v-slot:cell(area)="row">
          {{row.item.area.name}}
        </template>
         <template v-slot:cell(ubicacion)="row">
          {{row.item.location.name}}
        </template>
        <template v-slot:cell(option)="row">
          <b-button
            variant="none"
            size="sm"
            @click="row.toggleDetails"
            :class="!row.detailsShowing ? 'iq-bg-primary' : 'iq-bg-danger'"
            class="mr-2"
          >
            <i class="ri-close-circle-line ml-1" v-if="row.detailsShowing"></i>
            <i class="ri-eye-line ml-1" v-else></i>
          </b-button>
        </template>
        <template #row-details="row">
          <h6 class="pt-0"><strong>Descripción</strong></h6>
          <span>{{
            row.item.description !== null
              ? row.item.description
              : "Sin Descripción"
          }}</span>
        </template>
      </b-table>
      <b-pagination
        class="mt-3"
        v-model="currentPage"
        :total-rows="rows ? rows.length : 0"
        :per-page="perPage"
        first-number
        pills
        size="sm"
        align="center"
      ></b-pagination>
    </b-col>
    <b-modal id="details" size="lg" hide-footer>
      <template #modal-title> Detalles de Solicitudes </template>
      <div class="d-block">
        <b-row class="text-dark">
          <b-col md="5"><strong class="ml-5">FECHA: </strong></b-col>
          <b-col md="5" class="ml-auto mr-5">{{ details.date }}</b-col>

          <b-col md="5"><strong class="ml-5">TIPO: </strong></b-col>
          <b-col md="5" class="ml-auto mr-5">
            <b-badge
              pill
              :class="
                details.type_name === 'Ingreso' ? 'bg-success' : 'bg-danger'
              "
            >
              <h5 class="text-white">
                {{ details.type_name }}
              </h5>
            </b-badge></b-col
          >
          <b-col md="5"><strong class="ml-5">ÁREA: </strong></b-col>
          <b-col md="5" class="ml-auto mr-5">{{ details.area }}</b-col>

          <b-col md="5"><strong class="ml-5">UBICACIÓN: </strong></b-col>
          <b-col md="5" class="ml-auto mr-5">{{ details.ubicacion }}</b-col>

          <b-col md="5"><strong class="ml-5">STOCK: </strong></b-col>
          <b-col md="5" class="ml-auto mr-5">{{ details.quantity }}</b-col>

          <b-col md="5"><strong class="ml-5">DESCRIPCIÓN: </strong></b-col>
          <b-col md="5" class="ml-auto mr-5">{{ details.description }}</b-col>
        </b-row>
      </div>
    </b-modal>
  </b-row>
</template>
<script>
export default {
  props: {
    rows: null,
    colum: null,
    state: { default: false },
  },
  data () {
    return {
      filter: null,
      perPage: 5,
      currentPage: 1,
      totalRows: 0,
      details: ''
    }
  },
  methods: {
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    show (item) {
      this.details = ''
      this.details = item
      this.$bvModal.show('details')
    }
  }
}
</script>
