var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modals","size":"lg","hide-footer":"","no-close-on-backdrop":"","title":_vm.title}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"m-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('h4',{staticClass:"mb-2"},[_vm._v("Origen")]),_c('b-row',[_c('b-col',{attrs:{"md":"5"}},[_c('div',{staticClass:"form-group"},[_c('b-form-group',[_c('ValidationProvider',{attrs:{"name":"Ubicación de origen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"label":"name","placeholder":"Ubicación","options":_vm.locationes},on:{"input":function($event){return _vm.setLocation()}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados... ")]}}],null,true),model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}),(_vm.validation)?_c('small',{staticClass:"text-danger"},[_vm._v("Origen y destino no pueden ser iguales")]):_vm._e(),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1)]),_c('b-col',{attrs:{"md":"5"}},[_c('div',{staticClass:"form-group"},[_c('b-form-group',[_c('ValidationProvider',{attrs:{"name":"Área de origen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"label":"name","disabled":_vm.style,"options":_vm.areas.filter(function (item) { return item.quantity !== 0; }),"placeholder":"Área"},on:{"input":function($event){return _vm.setArea()}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados... ")]}}],null,true),model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}}),(_vm.validation)?_c('small',{staticClass:"text-danger"},[_vm._v("Origen y destino no pueden ser iguales")]):_vm._e(),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1)]),_c('b-col',{staticClass:"ml-auto",attrs:{"md":"2"}},[_c('b-form-input',{attrs:{"disabled":true,"type":"text","placeholder":"Cantidad"},model:{value:(_vm.quantityOrigin),callback:function ($$v) {_vm.quantityOrigin=$$v},expression:"quantityOrigin"}})],1)],1),_c('hr'),_c('h4',{staticClass:"mb-2"},[_vm._v("Destino")]),_c('b-row',[_c('b-col',{attrs:{"md":"5"}},[_c('div',{staticClass:"form-group"},[_c('b-form-group',[_c('ValidationProvider',{attrs:{"name":"Área de destino","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"label":"name","disabled":_vm.quantityOrigin == 0 ? true : false,"options":_vm.getUbication,"placeholder":"Ubicación","selectable":function (item) { return (item.id === 1 ? item.disabled : item); }},on:{"input":function($event){return _vm.setLocationD()}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados,"),_c('b-button',{attrs:{"variant":"link","block":""},on:{"click":function($event){return _vm.$refs.create.showModal()}}},[_vm._v("Agregar ubicación")])]}},{key:"option",fn:function(option){return [_vm._t("default",[_vm._v(" "+_vm._s(option.name)+" "),(option.id === 1 ? true : false)?_c('button',{staticClass:"btn btn-link btn-block",on:{"click":function($event){return _vm.$refs.create.showModal()}}},[_vm._v(" Agregar ubicación ")]):_vm._e()])]}}],null,true),model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}}),(_vm.validation)?_c('small',{staticClass:"text-danger"},[_vm._v("Origen y destino no pueden ser iguales")]):_vm._e(),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1)]),_c('b-col',{attrs:{"md":"5"}},[_c('div',{staticClass:"form-group"},[_c('b-form-group',[_c('ValidationProvider',{attrs:{"name":"Área de destino","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"label":"name","disabled":_vm.aread === true ? true : false,"placeholder":"Área","options":_vm.getAreaList,"selectable":function (item) { return (item.id === 1 ? item.disabled : item); }},on:{"input":function($event){return _vm.validateMovemet()}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados,"),_c('b-button',{attrs:{"variant":"link","block":""},on:{"click":function($event){return _vm.$refs.createOrUpdate.showModal()}}},[_vm._v("Agregar área")])]}},{key:"option",fn:function(option){return [_vm._t("default",[_vm._v(" "+_vm._s(option.name)+" "),(option.id === 1 ? true : false)?_c('button',{staticClass:"btn btn-link btn-block",on:{"click":function($event){return _vm.$refs.createOrUpdate.showModal()}}},[_vm._v(" Agregar área ")]):_vm._e()])]}}],null,true),model:{value:(_vm.form.area),callback:function ($$v) {_vm.$set(_vm.form, "area", $$v)},expression:"form.area"}}),(_vm.validation)?_c('small',{staticClass:"text-danger"},[_vm._v("Origen y destino no pueden ser iguales")]):_vm._e(),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1)]),_c('b-col',{attrs:{"md":"2"}},[_c('ValidationProvider',{attrs:{"name":"Cantidad","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"disabled":_vm.quantityOrigin == 0 ? true : false,"type":"number","placeholder":"Cantidad"},on:{"input":function($event){return _vm.setQuantity()}},model:{value:(_vm.form.quantity),callback:function ($$v) {_vm.$set(_vm.form, "quantity", $$v)},expression:"form.quantity"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),(_vm.warning)?_c('small',{staticClass:"text-danger"},[_vm._v("No puede seleccionar una cantidad mayor a la existente")]):_vm._e()],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"form-group"},[_c('b-form-group',{attrs:{"label":"Comentario"}},[_c('ValidationProvider',{attrs:{"name":"Comentario","rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"disabled":_vm.quantityOrigin == 0 ? true : false,"placeholder":"Ingresa una descripción referente al movimiento de stock","rows":"1","max-rows":"1"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1)])],1)],1)]}}])}),_c('hr'),_c('b-button',{staticClass:"mr-2",class:_vm.location !== '' &&
        _vm.form.area !== '' &&
        _vm.form.description !== '' &&
        _vm.form.quantity !== ''
          ? ''
          : 'disabled',attrs:{"variant":"primary"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v("Guardar ")]),_c('b-button',{staticClass:"mr-2",attrs:{"variant":" iq-bg-danger mr-1"},on:{"click":function($event){return _vm.$bvModal.hide('modals')}}},[_vm._v("Cerrar")])],1),_c('modals-ubication',{ref:"create"}),_c('modals',{ref:"createOrUpdate"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }